@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');


body {
  /* background-color: rgb(30 41 59); */
  background-color: rgb(235 235 235);
  font-family: "Black Ops One" 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
